import React from 'react'
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
  Slide, Typography, IconButton, TableContainer, Table,
  TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core'
import UserIcon from '@material-ui/icons/PermIdentity';
import ConractIcon from '@material-ui/icons/DescriptionOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CourseDetail = function ({ open, course, season, date, handleUserClick, handleContractClick, handleClose }) {

  if (!date || !course || !season) {
    return ('')
  }

  const backdrop = `calendar::${date.date}-${date._id}`

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth='xs'
      onClose={() => handleClose()}
      fullScreen={window.innerWidth < 400}
    >
      <DialogTitle>
        <span style={{ fontSize: '12px', fontWeight: '400' }}>
          {new Date(date.date).toLocaleDateString('de', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
        </span>
        <br />
        <span style={{ fontSize: '20px', fontWeight: '500' }}>
          {course.type === 'sewing' ? 'Nähkurs' : 'Themenkurs'}
        </span>
        <br />
        <span style={{ fontSize: '16px', fontWeight: '400' }}>
          {course.title.de + ', '}
          {course.type === 'sewing' ? `${course.timeframe.from} - ${course.timeframe.to}` : `${season.timeframe.from} - ${season.timeframe.to}`}
        </span>
        <br />
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          {!date.signups && 'Keine Anmeldungen'}
        </Typography>
        <TableContainer>
          <Table style={{ minWidth: '300px' }} size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {date.signups && Object.keys(date.signups).map((key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {date.signups[key].username}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleUserClick(date.signups[key].userId + '/bd/' + backdrop)}>
                      <UserIcon color='secondary' />
                    </IconButton>
                    <IconButton onClick={() => handleContractClick(key + '/bd/' + backdrop)}>
                      <ConractIcon color='secondary' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions style={{ marginTop: '20px' }}>
        <Button
          color="secondary"
          onClick={() => handleClose()}
        >
          Schliessen
                </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CourseDetail