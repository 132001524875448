export default {
  calendar: {
    title: 'Kalender'
  },
  signups: {
    title: 'Anmeldungen',
    sewing: 'Nähkurs',
    topic: 'Themenkurs',
    boerse: 'Kursbörse'
  },
  courses: {
    title: 'Kurse',
    sewing: 'Nähkurse',
    topics: 'Themenkurse',
    boerse: 'Kursbörse'
  },
  emails: {
    title: 'Emails'
  },
  webpage: {
    title: 'Webseite',
    main: 'Home',
    courses: 'Kursangebot',
    atelier: 'Offenes Atelier'
  },
  users: {
    title: 'Benutzer'
  },
  user: {
    login: 'Anmelden',
    logout: 'Abmelden'
  }
}