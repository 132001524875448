import React, { Component } from 'react'
import { withFirebase } from '../../utils/firebase'
import {
  Grid, List, Typography, Divider,
} from '@material-ui/core'
import Loader from '../../templates/Loader'
import CourseDate from './elements/CourseDate'
import CourseDetail from './elements/CourseDetail'
import clsx from "clsx";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { DatePicker } from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import { IconButton, withStyles, Button } from "@material-ui/core";
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';

class Calendar extends Component {
  constructor(props) {
    super(props)

    let hash = window.location.hash.split('/').reverse()[0].split('-')
    let date = new Date()

    if (hash[0] !== 'calendar' && parseInt(hash[0])) {
      date = new Date(parseInt(hash[0]))
    }
    let startdate = date
    startdate.setHours(0, 0, 0, 0)
    startdate.setDate(startdate.getDate() - (startdate.getDay() + 6) % 7) // last Monday

    let enddate = new Date(startdate.getTime())
    enddate.setHours(0, 0, 0, 0)
    enddate.setDate(enddate.getDate() + 7)  // next Sunday

    this.state = {
      loading: true,
      data: null,
      startdate: startdate.getTime(),
      enddate: enddate.getTime(),
      courses: {},
      seasons: {},
      dates: {},
      path_date: hash[1],
      selectedDate: startdate,
      selected: {
        course: null,
        season: null,
        date: null
      },
      open: ''
    }
  }

  componentDidMount() {
    const { startdate, enddate, path_date } = this.state
    this._loadDates(startdate, enddate)

    if (!path_date) {
      window.location.hash = `#/calendar/${startdate}`
    }
  }

  _loadDates(startdate, enddate) {
    const { courses, seasons } = this.state

    this.props.firebase.getCalendar(startdate, enddate)
      .then((dates) => {
        this.setState({
          loading: false,
          dates: dates
        })
        let courseIds = []
        let seasonIds = []

        dates.map((date) => {
          let path = date._path.split('/')
          if (!courses[path[1]]) {
            courseIds.push(path[1])
          }
          if (!seasons[path[3]]) {
            seasonIds.push({
              courseId: path[1],
              seasonId: path[3]
            })
          }
        })
        let promises = []
        courseIds.map((courseId) => {
          promises.push(this.props.firebase.getCourse(courseId))
        })
        seasonIds.map(({ courseId, seasonId }) => {
          promises.push(this.props.firebase.getSeason(courseId, seasonId))
        })
        return promises
      })
      .then((promises) => Promise.all(promises))
      .then((res) => {
        return res.map((item) => {
          if (item.hasOwnProperty('type')) {
            courses[item._id] = item
          } else {
            seasons[item._id] = item
          }
        })
      })
      .then(() => {
        const { path_date, dates } = this.state
        let selected = {
          course: null,
          season: null,
          date: null
        }
        if (path_date) {
          const _date = dates.filter(date => date._id === path_date)
          const aIds = _date[0] && _date[0]._path.split('/')
          if (aIds) {
            selected = {
              course: courses[aIds[1]],
              season: seasons[aIds[3]],
              date: dates.filter(date => date._id === aIds[5])[0],
            }
          }
        }
        this.setState({
          loading: false,
          courses,
          seasons,
          selected,
          open: path_date ? 'course' : ''
        })
      })
      .catch(e => console.error(e))
  }

  handleWeekChange = date => {

    let startdate = date
    startdate.setDate(startdate.getDate() - (startdate.getDay() + 6) % 7) // last Monday
    startdate.setHours(0, 0, 0, 0)

    let enddate = new Date(startdate.getTime())
    enddate.setDate(enddate.getDate() + 7)  // next Sunday

    this.setState({
      selectedDate: startOfWeek(startdate),
      startdate: startdate.getTime(),
      enddate: enddate.getTime(),
      loading: true,
      dates: []
    })
    this._loadDates(startdate.getTime(), enddate.getTime())

    window.location.hash = `#/calendar/${startdate.getTime()}`
  }

  formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = date
    let monday = startOfWeek(dateClone)
    monday.setDate(monday.getDate() + 1)

    return monday && isValid(monday)
      ? `Woche vom ${monday.toLocaleDateString('de', { month: 'long', day: 'numeric' })}`
      : invalidLabel;
  }

  changeWeek(mode) {
    let { startdate, enddate, selectedDate } = this.state
    let _startdate = new Date(startdate)
    let _enddate = new Date(enddate)

    switch (mode) {
      case 'next':
        _startdate.setDate(_startdate.getDate() + 7)
        _enddate.setDate(_enddate.getDate() + 7)
        selectedDate.setDate(selectedDate.getDate() + 7)
        break;
      case 'prev':
        _startdate.setDate(_startdate.getDate() - 7)
        _enddate.setDate(_enddate.getDate() - 7)
        selectedDate.setDate(selectedDate.getDate() - 7)
        break;
    }
    startdate = _startdate.getTime()
    enddate = _enddate.getTime()

    this.setState({
      startdate,
      enddate,
      selectedDate,
      loading: true
    })
    this._loadDates(startdate, enddate)
    window.location.hash = `#/calendar/${startdate}`
  }

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props;
    let dateClone = date;
    let selectedDateClone = selectedDate;

    let start = startOfWeek(selectedDateClone);
    let end = endOfWeek(selectedDateClone);

    start.setDate(start.getDate() + 1)
    end.setDate(end.getDate() + 1)

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    )
  }

  handleClose() {
    const { startdate } = this.state
    window.location.hash = `#/calendar/${startdate}`
    this.setState({
      open: ''
    })
  }

  handleOpen(course, season, date) {
    const { startdate } = this.state
    window.location.href = `#/calendar/${startdate}-${date._id}`
    this.setState({
      selected: {
        course: course,
        season: season,
        date: date
      },
      open: 'course'
    })
  }

  handleUserClick(userId) {
    window.location.href = `#/users/${userId}`
  }

  handleContractClick(signupId) {
    window.location.href = `#/signups/${signupId}`
  }

  render() {
    const { loading, courses, seasons, dates, selectedDate, startdate, enddate, open, selected } = this.state

    return (
      <div className='app_content'>
        <Grid container className='app_padding'>
          <Grid item xs={12}>
            <Typography variant='h4'>Kalender</Typography>
            <br />
          </Grid>
          <Grid item xs={3}>
            <Button color='secondary' onClick={this.changeWeek.bind(this, 'prev')}>
              Zurück
            </Button>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Woche auswählen"
              value={selectedDate}
              onChange={this.handleWeekChange}
              renderDay={this.renderWrappedWeekDay}
              labelFunc={this.formatWeekSelectLabel}
              color='secondary'
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'right' }}>
            <Button color='secondary' onClick={this.changeWeek.bind(this, 'next')}>
              Weiter
            </Button>
          </Grid>
          {loading ? <Loader temporary={true} /> :
            <Grid item xs={12}>
              <Divider />
              {dates && dates.length > 0 ? <List dense>
                {dates.map((date) => {
                  let path = date._path.split('/')
                  return (
                    <CourseDate
                      key={date._id}
                      date={date}
                      season={seasons[path[3]]}
                      course={courses[path[1]]}
                      handleOpen={this.handleOpen.bind(this)}
                    />
                  )
                })}
              </List>
                :
                <div style={{ width: '100%', height: '60vh', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                  <EmojiFoodBeverageIcon style={{ fontSize: '3.5rem', color: '#e1e1e1' }} />
                  <Typography style={{ fontSize: '1.5rem', color: '#e1e1e1' }} >
                    {new Date(startdate).toLocaleDateString() + ' - ' + new Date(enddate - 1).toLocaleDateString()}
                  </Typography>
                  <Typography style={{ fontSize: '1rem', color: '#e1e1e1' }} >
                    In dieser Woche finden keine Kurse statt.
                  </Typography>
                </div>
              }
            </Grid>
          }
        </Grid>
        <CourseDetail
          open={open === 'course'}
          course={selected.course}
          season={selected.season}
          date={selected.date}
          handleUserClick={this.handleUserClick.bind(this)}
          handleContractClick={this.handleContractClick.bind(this)}
          handleClose={this.handleClose.bind(this)}
        />
      </div>
    )
  }
}

const styles = createStyles(theme => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(withFirebase(Calendar))