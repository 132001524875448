import thunk from 'redux-thunk';
import logger from 'redux-logger'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { loadTranslations, setLocale, syncTranslationWithStore, i18nReducer } from 'react-redux-i18n';
import translationsObject from '../i18n'
import reducers from '../reducers'

const store = createStore(
    combineReducers({
        data: reducers,
        i18n: i18nReducer
    }),
    applyMiddleware(thunk)
)

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale('de'));


export default store