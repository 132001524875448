import React, { Component } from 'react';
import AppBar from './AppBar'
import Drawer from './Drawer'

import './Nav.css'

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawer_open: false,
      scrollposition: 0,
      height: 0,
      width: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._scroll.bind(this))
    this._scroll()
    window.addEventListener('resize', this._resize.bind(this))
    this._resize()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._scroll.bind(this))
    window.removeEventListener('resize', this._resize.bind(this))
  }

  _resize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  _scroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height
    const scrollposition = scrolled ? scrolled : 0

    this.setState({
      scrollposition
    })
  }

  _index_mapping = {
    'courses': 0,
    'webpage': 1,
    'emails': 2,
  }

  _toggle_drawer() {
    this.setState(state => ({
      drawer_open: !state.drawer_open
    }))
  }


  _change_environment(value) {
    console.log(value);
  }

  render() {
    const { drawer_open, scrollposition, width } = this.state
    const { active_path } = this.props

    return (
      <div className='nav_wrapper'>
        <AppBar
          width={width}
          selected_index={this._index_mapping[active_path || 'courses']}
          scrollposition={scrollposition}
          toggle_drawer={this._toggle_drawer.bind(this)}
          change_environment={this._change_environment.bind(this)}
        />
        <Drawer
          drawer_open={drawer_open}
          toggle_drawer={this._toggle_drawer.bind(this)}
          change_environment={this._change_environment.bind(this)}
        />
      </div>
    )
  }
}

export default Nav