import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import Firebase, { FirebaseContext } from './utils/firebase';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import deLocale from 'date-fns/locale/de';
import theme from './assets/theme'
import store from './utils/store'
import Router from './components/router/Router'
import DateFnsUtils from '@date-io/date-fns';
import './index.css';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <Router />
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root'));

serviceWorker.unregister();