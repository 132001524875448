import {
  GET_STATIC_DATA,
  REQUEST_STATIC_DATA,
  RECEIVE_STATIC_DATA,
} from '../actions';

const initialState = {
  loading: false,
  static: {}
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STATIC_DATA:
      console.log('reducer', action)
      return {
        static: { test: 'efwrfgrg' },
        ...state
      }
    case REQUEST_STATIC_DATA:
      return {
        loading: true,
        ...state
      }
    case RECEIVE_STATIC_DATA:
      return {
        loading: false,
        ...state
      }
    default:
      return state
  }
}

export default rootReducer;