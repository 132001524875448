import firebase from '../firebase'


export const GET_STATIC_DATA = "GET_STATIC_DATA";
export function getStaticData(name) {
  return dispatch => {
    dispatch(requestStaticData())

    console.log(firebase)

    return firebase.appdata(name).get()
      .then((doc) => {
        dispatch(receiveStaticData(doc.data))
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
}


export const REQUEST_STATIC_DATA = 'REQUEST_STATIC_DATA'
function requestStaticData() {
  return {
    type: REQUEST_STATIC_DATA,
  }
}

export const RECEIVE_STATIC_DATA = 'RECEIVE_STATIC_DATA'
function receiveStaticData(page, json) {
  return {
    type: RECEIVE_STATIC_DATA,
    page,
    json
  }
}