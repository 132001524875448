import React from 'react';
import { Grid, ListItem, ListItemText, Divider } from '@material-ui/core'
import Loader from '../../../templates/Loader'

export default function CourseDate({ course, season, date, handleOpen, backdrop }) {

	if (!course || !season || !date) {
		return (<Loader />)
	}

	return (
		<div key={date._id}>
			<ListItem button onClick={() => handleOpen(course, season, date)}>
				<ListItemText primary={
					<Grid container>
						<Grid item xs={12} sm={6}>
							<span style={{ fontSize: '12px' }}>
								{new Date(date.date).toLocaleDateString('de', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
							</span>
							<br />
							<span style={{ fontSize: '20px' }}>
								{course.type === 'sewing' ? 'Nähkurs' : 'Themenkurs'}
							</span>
						</Grid>
						<Grid item xs={10} sm={5}
							style={{ fontSize: '14px' }}>
							{course.title.de}
							<br />
							{course.type === 'sewing' ? `${course.timeframe.from} - ${course.timeframe.to}` : `${season.timeframe.from} - ${season.timeframe.to}`}
						</Grid>
						<Grid item xs={2} sm={1}
							style={{ fontSize: '20px', marginTop: '8px', color: '#848484' }}>
							{(date.signups ? Object.keys(date.signups).length : '0') + '/' + season.max_signups}
						</Grid>
					</Grid>
				} />
			</ListItem>
			<Divider />
		</div>
	)
}