import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { withFirebase } from '../../utils/firebase'
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore
} from '@material-ui/icons'


function CustomDrawer(props) {

  const [courses_expand, set_courses_expand] = React.useState(false)
  const [webpage_expand, set_webpage_expand] = React.useState(false)
  const [main_expand, set_main_expand] = React.useState(false)

  const [selected_environment, set_selected_environment] = React.useState(localStorage.getItem('_e') || 'prod')

  const _onClick = (value) => {
    window.location.hash = '#/' + value
    props.toggle_drawer()
  }

  const _change_environment = (value) => {

    props.change_environment(value)
    localStorage.setItem('_e', value)

    window.location.reload()
  }

  return (
    <SwipeableDrawer
      open={props.drawer_open}
      onClose={props.toggle_drawer}
      onOpen={props.toggle_drawer}
    >
      <List className='drawer'>
        {
          /*
            <ListItem>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>Webseite</InputLabel>
                <Select
                  value={selected_environment}
                  onChange={(e) => _change_environment(e.target.value)}
                >
                  <MenuItem value={'prod'}>fadenwerk-murten.ch</MenuItem>
                  <MenuItem value={'beta'}>beta.fadenwerk-murten.ch</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <Divider />*/
        }
        <ListItem button onClick={(e) => _onClick('calendar')}>
          <ListItemText primary={<T value="calendar.title" />} />
        </ListItem>
        <Divider />
        <ListItem button onClick={(e) => _onClick('signups')}>
          <ListItemText primary={<T value="signups.title" />} />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => courses_expand ? set_courses_expand(false) : set_courses_expand(true)}>
          <ListItemText primary={<T value="courses.title" />} />
          {courses_expand ? <ExpandLess color='secondary' /> : <ExpandMore color='secondary' />}
        </ListItem>
        <Collapse in={courses_expand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('courses/sewing')}>
              <ListItemText primary={<T value="courses.sewing" />} />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('courses/topic')}>
              <ListItemText primary={<T value="courses.topics" />} />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('courses/boerse')}>
              <ListItemText primary={<T value="courses.boerse" />} />
            </ListItem>
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={() => webpage_expand ? set_webpage_expand(false) : set_webpage_expand(true)}>
          <ListItemText primary={<T value="webpage.title" />} />
          {webpage_expand ? <ExpandLess color='secondary' /> : <ExpandMore color='secondary' />}
        </ListItem>
        <Collapse in={webpage_expand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/home')}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/courses/sewing')}>
              <ListItemText primary={<T value="courses.sewing" />} />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/courses/topic')}>
              <ListItemText primary={<T value="courses.topics" />} />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/boerse')}>
              <ListItemText primary={<T value="courses.boerse" />} />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/aboutme')}>
              <ListItemText primary="Über Mich" />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/gallery')}>
              <ListItemText primary="Galerie" />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/contact')}>
              <ListItemText primary="Kontakt" />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/signup')}>
              <ListItemText primary="Anmeldung" />
            </ListItem>
            <Divider />
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/impressum')}>
              <ListItemText primary="Impressum" />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/datenschutz')}>
              <ListItemText primary="Datenschutz" />
            </ListItem>
            <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('edit/cookies')}>
              <ListItemText primary="Cookies" />
            </ListItem>
          </List>
          <Divider />
        </Collapse>
        <Divider />
        <ListItem button onClick={() => props.firebase.signout()}>
          <ListItemText primary={<T value="user.logout" />} />
        </ListItem>
      </List>
    </SwipeableDrawer>
  )
}

export default withFirebase(CustomDrawer)