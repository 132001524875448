import React, { Suspense } from "react";
import { HashRouter, Route, Switch } from 'react-router-dom';
import { withFirebase } from '../../utils/firebase'

import Auth from '../../pages/auth/Auth'
import Loader from '../../templates/Loader'
import Nav from '../nav/Nav'
import Calendar from '../../pages/calendar/Calendar'

import '../../pages/Pages.css'

const CourseSewing = React.lazy(() => import('../../pages/coursesewing/CourseSewing'));
const CourseSewingDates = React.lazy(() => import('../../pages/coursesewing/CourseSewingDates'));
const CourseSewingSummary = React.lazy(() => import('../../pages/coursesewing/CourseSewingSummary'));
const CourseTopic = React.lazy(() => import('../../pages/coursetopic/CourseTopic'));
const CourseTopicDates = React.lazy(() => import('../../pages/coursetopic/CourseTopicDates'));
const Boerse = React.lazy(() => import('../../pages/boerse/Boerse'));
const Signups = React.lazy(() => import('../../pages/signups/Signups'));
const SignupDetail = React.lazy(() => import('../../pages/signups/SignupDetail'));
const Users = React.lazy(() => import('../../pages/users/Users'));
const UserDetail = React.lazy(() => import('../../pages/users/UserDetail'));
const CourseSignUp = React.lazy(() => import('../../pages/coursesignup/CourseSignUp'));
const CourseSignUpSummary = React.lazy(() => import('../../pages/coursesignup/CourseSignUpSummary'));
const CourseTopicSummary = React.lazy(() => import('../../pages/coursetopic/CourseTopicSummary'));


const EditCourseSewing = React.lazy(() => import('../../pages/editor/CourseSewing'))
const EditCourseTopic = React.lazy(() => import('../../pages/editor/CourseTopic'))
const EditBoerse = React.lazy(() => import('../../pages/editor/Boerse'))
const EditImpressum = React.lazy(() => import('../../pages/editor/Impressum'))
const EditDatenschutz = React.lazy(() => import('../../pages/editor/Datenschutz'))
const EditCookies = React.lazy(() => import('../../pages/editor/Cookies'))
const EditAboutMe = React.lazy(() => import('../../pages/editor/AboutMe'))
const EditSignUp = React.lazy(() => import('../../pages/editor/SignUp'))
const EditContact = React.lazy(() => import('../../pages/editor/Contact'))
const EditHome = React.lazy(() => import('../../pages/editor/Home'))
const EditGallery = React.lazy(() => import('../../pages/editor/Gallery'))

const NotFound = React.lazy(() => import('../../pages/NotFound'));



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active_path: 'courses',
      user: null
    }
  }

  componentDidMount() {
    window.addEventListener("hashchange", this._hashChange.bind(this), false);
    this._hashChange()

    this.props.firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        this.setState({ user })
      } else {
        this.setState({ user: null })
      }
    }.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this._hashChange.bind(this), false);
  }

  _hashChange() {
    const currentHash = window.location.hash.split('/')
    if (currentHash[1] !== '') {
      this.setState({
        active_path: currentHash[1]
      })
    } else {
      window.location.hash = '#/calendar'
    }
  }

  render() {
    const { user, active_path } = this.state

    if (!user) {
      return (
        <Auth />
      )
    }

    return (
      <HashRouter>
        <Nav active_path={active_path} />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/calendar" render={() => <Calendar />} />
            <Route exact path="/courses/sewing" render={() => <CourseSewing />} />
            <Route exact path="/courses/sewing/:courseId" render={() => <CourseSewingDates />} />
            <Route exact path="/courses/sewing/:courseId/:seasonId" render={() => <CourseSewingSummary />} />
            <Route exact path="/courses/topic" render={() => <CourseTopic />} />
            <Route exact path="/courses/topic/:courseId" render={() => <CourseTopicDates />} />
            <Route exact path="/courses/topic/:courseId/:seasonId" render={() => <CourseTopicSummary />} />
            <Route exact path="/courses/boerse" render={() => <Boerse />} />
            <Route exact path="/signups" render={() => <Signups />} />
            <Route path="/signups/:signupId" render={() => <SignupDetail />} />
            <Route exact path="/users" render={() => <Users />} />
            <Route path="/users/:userId" render={() => <UserDetail />} />
            <Route path="/signup/summary/:signupId" render={() => <CourseSignUpSummary />} />
            <Route path="/signup/:courseid/:dateId" render={() => <CourseSignUp />} />

            <Route path="/edit/courses/sewing" render={() => <EditCourseSewing />} />
            <Route path="/edit/courses/topic" render={() => <EditCourseTopic />} />
            <Route path="/edit/boerse" render={() => <EditBoerse />} />
            <Route path="/edit/impressum" render={() => <EditImpressum />} />
            <Route path="/edit/datenschutz" render={() => <EditDatenschutz />} />
            <Route path="/edit/cookies" render={() => <EditCookies />} />
            <Route path="/edit/aboutme" render={() => <EditAboutMe />} />
            <Route path="/edit/signup" render={() => <EditSignUp />} />
            <Route path="/edit/contact" render={() => <EditContact />} />
            <Route path="/edit/home" render={() => <EditHome />} />
            <Route path="/edit/gallery" render={() => <EditGallery />} />


            <Route render={() => <NotFound />} />
          </Switch>
        </Suspense>
      </HashRouter >
    )
  }
}

export default withFirebase(App)