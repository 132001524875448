import React, { Component } from 'react'
import { withFirebase } from '../../utils/firebase'
import {
  Box,
  Button,
  TextField,
  Typography
} from '@material-ui/core'
import Loader from '../../templates/Loader'

class Auth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: null,
      loading: false
    }
  }

  handleChanche(property, value) {
    this.setState({
      [property]: value
    })
  }

  handleSignIn() {
    const { email, password } = this.state
    this.setState({ loading: true })
    this.props.firebase.signin(email, password)
      .catch((error) => {
        this.setState({ error, password: '', loading: false })
      })
  }

  render() {
    const { email, password, error, loading } = this.state
    if (loading) {
      return (<Loader />)
    }
    return (
      <Box className='app_auth_content'>
        <TextField
          style={{ minWidth: '250px', marginTop: '20px' }}
          label='Email'
          type='email'
          value={email}
          onChange={(e) => this.handleChanche('email', e.target.value)}
        />
        <TextField
          style={{ minWidth: '250px', marginTop: '20px' }}
          label='Passwort'
          type='password'
          value={password}
          onChange={(e) => this.handleChanche('password', e.target.value)}
        /><br />
        <Button
          style={{ minWidth: '250px', marginTop: '30px' }}
          color='secondary'
          onClick={() => this.handleSignIn()}
        >
          Anmelden
        </Button><br />
        {error && <Typography variant='subtitle1' style={{ padding: '0 16px', textAlign: 'center' }}>{error.message}</Typography>}
      </Box >
    )
  }
}

export default withFirebase(Auth)