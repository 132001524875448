import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/database'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

class Firebase {
  constructor() {

    app.initializeApp(config);

    this.firebase = app
    this.auth = app.auth()
    this.firestore = app.firestore()
    this.storage = app.storage()
    this.database = app.database();

    this.state = {
      static_data: {}
    }
  }

  paths = {
    courses: 'courses',
    seasons: 'seasons',
    dates: 'dates',
    signups: 'signups',
    users: 'users'
  }

  ref_courses = () => this.firestore.collection(this.paths.courses)
  ref_seasons = (courseId) => this.firestore.collection(`${this.paths.courses}/${courseId}/${this.paths.seasons}`)
  ref_dates = (courseId, seasonId) => this.firestore.collection(`${this.paths.courses}/${courseId}/${this.paths.seasons}/${seasonId}/${this.paths.dates}`)
  ref_dates_grp = () => this.firestore.collectionGroup(this.paths.dates)
  ref_signups = () => this.firestore.collection(this.paths.signups)
  ref_users = () => this.firestore.collection(this.paths.users)

  getCourse(courseId) {
    return this._getPromise(
      this.ref_courses(), courseId
    )
  }

  getSeason(courseId, seasonId) {
    return this._getPromise(
      this.ref_seasons(courseId), seasonId
    )
  }

  getDate(courseId, seasonId, dateId) {
    return this._getPromise(
      this.ref_dates(courseId, seasonId), dateId
    )
  }

  _getPromise(ref, id) {
    return new Promise((resolve, reject) => ref.doc(id).get()
      .then((doc) => {
        resolve(this.__doc_to_json(doc))
      })
      .catch(e => reject(e)))
  }

  getCourses(type) {
    return new Promise((resolve, reject) => this.ref_courses()
      .where('type', '==', type)
      .orderBy('index', 'asc')
      .get()
      .then((snap) => {
        resolve(snap.docs.map(doc => {
          return this.__doc_to_json(doc)
        }))
      })
      .catch((e) => reject(e)))
  }

  getSeasons(courseId) {
    return new Promise((resolve, reject) => this.ref_seasons(courseId)
      .where('closed', '==', false)
      .orderBy('startdate', 'asc')
      .get()
      .then((snap) => {
        resolve(snap.docs.map(doc => {
          return this.__doc_to_json(doc)
        }))
      })
      .catch((e) => reject(e)))
  }

  getDates(courseId, seasonId) {
    return new Promise((resolve, reject) => this.ref_dates(courseId, seasonId)
      .orderBy('date', 'asc')
      .get()
      .then((snap) => {
        resolve(snap.docs.map(doc => {
          return this.__doc_to_json(doc)
        }))
      })
      .catch((e) => reject(e)))
  }

  __doc_to_json(doc) {
    if (!doc.exists) {
      return {}
    }
    const data = doc.data()
    data._id = doc.id
    data._path = doc.ref.path
    return data
  }

  setCourse(course, courseId = false) {
    return this._setPromise(
      this.ref_courses(), course, courseId
    )
  }

  setSeason(courseId, season, seasonId = false) {
    return this._setPromise(
      this.ref_seasons(courseId), season, seasonId
    )
  }

  setDate(courseId, seasonId, date, dateId = false) {
    return this._setPromise(
      this.ref_dates(courseId, seasonId), date, dateId
    )
  }

  _setPromise(ref, data, id) {
    if (id) { // update
      return new Promise((resolve, reject) => ref.doc(id).set(data, { merge: true })
        .then(() => resolve(id))
        .catch(e => reject(e)))
    } else {  // new
      return new Promise((resolve, reject) => ref.add(data)
        .then(({ id }) => resolve(id))
        .catch(e => reject(e)))
    }
  }

  deleteCourse(courseId) {
    return this._deletePromise(
      this.ref_courses(),
      courseId
    )
  }

  deleteSeason(courseId, seasonId) {
    return this._deletePromise(
      this.ref_seasons(courseId),
      seasonId
    )
  }

  deleteDate(courseId, seasonId, dateId) {
    return this._deletePromise(
      this.ref_dates(courseId, seasonId),
      dateId
    )
  }

  _deletePromise(ref, id) {
    return new Promise((resolve, reject) => ref.doc(id)
      .delete()
      .then(() => resolve())
      .catch(e => reject(e)))
  }

  getCalendar(from, to) {
    return new Promise((resolve, reject) => this.ref_dates_grp()
      .where('date', '>=', from)
      .where('date', '<', to)
      .orderBy('date', 'asc')
      .get()
      .then((snap) => resolve(snap.docs.map(doc => this.__doc_to_json(doc))))
      .catch(e => reject(e)))
  }

  getSignups(limit, only_unpaid) {
    limit = parseInt(limit)
    if (only_unpaid) {
      return new Promise((resolve, reject) => this.ref_signups()
        .where('paid', '==', false)
        .orderBy('timestamp', 'desc')
        .limit(limit)
        .get()
        .then((snap) => resolve(snap.docs.map(doc => this.__doc_to_json(doc))))
        .catch(e => reject(e)))
    } else {
      return new Promise((resolve, reject) => this.ref_signups()
        .orderBy('timestamp', 'desc')
        .limit(limit)
        .get()
        .then((snap) => resolve(snap.docs.map(doc => this.__doc_to_json(doc))))
        .catch(e => reject(e)))
    }
  }

  getSignup(signupId) {
    return new Promise((resolve, reject) => this.ref_signups()
      .doc(signupId)
      .get()
      .then((doc) => resolve(this.__doc_to_json(doc)))
      .catch(e => reject(e)))
  }

  getUser(userId) {
    return new Promise((resolve, reject) => this.ref_users()
      .doc(userId)
      .get()
      .then((doc) => resolve(this.__doc_to_json(doc)))
      .catch(e => reject(e)))
  }

  getUsers(firstname, lastname) {
    if (firstname && lastname) {
      return new Promise((resolve, reject) => this.ref_users()
        .where('firstname', '==', firstname)
        .where('lastname', '==', lastname)
        .get()
        .then((snap) => resolve(snap.docs.map(doc => this.__doc_to_json(doc))))
        .catch(e => reject(e)))
    } else {
      let property = firstname !== '' ? 'firstname' : 'lastname'
      let value = firstname !== '' ? firstname : lastname

      return new Promise((resolve, reject) => this.ref_users()
        .where(property, '==', value)
        .get()
        .then((snap) => resolve(snap.docs.map(doc => this.__doc_to_json(doc))))
        .catch(e => reject(e)))
    }
  }

  getStatic({ key, subkey }) {
    return new Promise(function (resolve, reject) {
      const static_data = this.state.static_data

      if (subkey) {
        if (static_data[key] && static_data[key][subkey]) {
          return resolve(static_data[key][subkey])
        }
      } else {
        if (static_data[key]) {
          if(key !== 'topic'){
            if (key !== 'home') {
              return resolve(static_data[key])

            } else if (static_data[key].main) {
              return resolve(static_data[key])
            }
          }
        }
      }
      const path = 'static/' + key + (subkey ? '/' + subkey : '/')

      this.database.ref(path).once('value')
        .then((snap) => {
          const data = snap.val()
          this.state.static_data[key] = data
          resolve(data)
        })
        .catch((e) => reject(e))
    }.bind(this))
  }

  setStatic({ key, subkey }, data) {
    return new Promise((resolve, reject) => {
      const static_data = this.state.static_data

      if (subkey) {
        static_data[key][subkey] = data
      } else {
        static_data[key] = data
      }

      this.state.static_data = static_data

      const path = 'static/' + key + (subkey ? '/' + subkey : '/')
      this.database.ref(path).set(data)
        .then(() => resolve())
        .catch(e => reject(e))
    })
  }

  addTopic(oTopic) {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.database.ref('static/topic/topics/').once('value'),
        this.ref_courses()
          .where('type', '==', 'topic')
          .orderBy('index', 'asc')
          .get()
      ])
      .then(res => {
        const data = res[0].val() || []
        const topiccourses = res[1].docs.map(doc => {
          return this.__doc_to_json(doc)
        })
        var data_sorted = []
        var added_subtopic_keys = []
        
        data.push(oTopic)
        
        topiccourses.forEach((course) => {
          data.forEach(data_item => {
            if(data_item.topicId === course._id){
              data_sorted.push(data_item)
            }else if(data_item.subtopics) {
              data_item.subtopics.forEach(sub_item => {
                if(sub_item.topicId === course._id && added_subtopic_keys.indexOf(data_item.key) < 0) {
                  data_sorted.push(data_item)
                  added_subtopic_keys.push(data_item.key)
                }
              })
            }
          })
        })
        return data_sorted
      })
      .then((data) => this.database.ref('static/topic/topics/').set(data))
      .then(() => resolve())
      .catch(e => reject(e))
    })
  }

  orderTopics() {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.database.ref('static/topic/topics/').once('value'),
        this.ref_courses()
          .where('type', '==', 'topic')
          .orderBy('index', 'asc')
          .get()
      ])
      .then(res => {
        const data = res[0].val()
        const topiccourses = res[1].docs.map(doc => {
          return this.__doc_to_json(doc)
        })
        var data_sorted = []
        var added_subtopic_keys = []
        
        topiccourses.forEach((course) => {
          data.forEach(data_item => {
            if(data_item.topicId === course._id){
              data_sorted.push(data_item)
            }else if(data_item.subtopics) {
              data_item.subtopics.forEach(sub_item => {
                if(sub_item.topicId === course._id && added_subtopic_keys.indexOf(data_item.key) < 0) {
                  data_sorted.push(data_item)
                  added_subtopic_keys.push(data_item.key)
                }
              })
            }
          })
        })
        return data_sorted
      })
      .then((data) => this.database.ref('static/topic/topics/').set(data))
      .then(() => resolve())
      .catch(e => reject(e))
    })
  }

  deleteTopic(topicId) {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.database.ref('static/topic/topics/').once('value'),
        this.ref_courses()
          .where('type', '==', 'topic')
          .orderBy('index', 'asc')
          .get()
      ])
      .then(res => {
        const data = res[0].val()
        const topiccourses = res[1].docs.map(doc => {
          return this.__doc_to_json(doc)
        })

        var data_sorted = []

        data.forEach(d => {
          if(d.topicId && d.topicId !== topicId){
            data_sorted.push(d)
          }else if(d.subtopics){
            var _sub = d
            var _subtopics = []

            d.subtopics.forEach(d_sub => {
              
              if(d_sub.topicId !== topicId){
                _subtopics.push(d_sub)
              }
            })
            d.subtopics = _subtopics
            if(_subtopics.length > 0){
              data_sorted.push(_sub)
            }
          }
        })

        return data_sorted
      })
      .then((data) => this.database.ref('static/topic/topics/').set(data))
      .then(() => resolve())
      .catch(e => reject(e))
    })
  }

  // sign-in /out
  signin(email, password) {
    return new Promise((resolve, reject) => {
      this.firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
        resolve(user)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  signout() {
    this.firebase.auth().signOut().catch(function (error) {
      console.log(error)
    });
  }

  setSignUp(signup) {
    return new Promise(function (resolve, reject) {
      if (this._validateSignUp(signup) && this._validateUser(signup.user)) {
        this.firestore.collection('signups').add(signup)
          .then(({ id }) => {
            signup._id = id
            resolve(signup)
          })
          .catch((error) => reject(error))
      } else {
        reject('SignUp not valid')
      }
    }.bind(this))
  }

  deleteSignUp(signup) {

    if (signup.type === 'boerse') {
      var ref_signup = {
        _id: signup._id,
        courseId: signup._ref_courseId,
        seasonId: signup._ref_seasonId
      }

      return new Promise(function (resolve, reject) {
        this._removeSignupFromDates(ref_signup)
          .then(() => this._updateSignupCountOnSeason(signup))
          .then(() => this._setDeletedPropertyOnSignup(signup))
          .then(() => resolve())
          .catch((err) => reject(err))
      }.bind(this))

    } else {
      return new Promise(function (resolve, reject) {
        this._deleteMissoutDate(signup)
          .then(() => this._removeSignupFromDates(signup))
          .then(() => this._updateSignupCountOnSeason(signup))
          .then(() => this._setDeletedPropertyOnSignup(signup))
          .then(() => resolve())
          .catch((err) => reject(err))
      }.bind(this))
    }

  }

  _deleteMissoutDate(signup) {
    return new Promise(function (resolve, reject) {
      if (signup.missoutdateId) {
        this.firestore.collection('courses/boerse/seasons')
          .where('dateId', '==', signup.missoutdateId)
          .where('full', '==', false)
          .where('locked', '==', false)
          .where('closed', '==', false)
          .get()
          .then((dates) => dates.docs.map((doc) => this.__doc_to_json(doc)))
          .then((data) => {
            if (data.length <= 0) {
              reject('Ausfalldatum wurde bereits vergeben. (Ausfalldatum: ' + new Date(signup.missoutdate).toLocaleDateString('de') + ')')
            } else {
              return data[0]._id
            }
          })
          .then((boerseId) => this.firestore.collection('courses/boerse/seasons/').doc(boerseId).delete())
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      } else {
        resolve(true)
      }
    }.bind(this))
  }

  _removeSignupFromDates(signup) {
    return new Promise(function (resolve, reject) {
      const dates_path = 'courses/' + signup.courseId + '/seasons/' + signup.seasonId + '/dates'

      this.firestore.collection(dates_path)
        .get()
        .then(data => data.docs.map(doc => this.__doc_to_json(doc)))
        .then(data => data.map(function (item) {
          if (!item.signups || !item.signups[signup._id]) {
            resolve()
            return
          }
          var new_signups = item.signups
          delete new_signups[signup._id]

          return this.firestore.collection(dates_path).doc(item._id).update({
            signups: new_signups
          })
        }.bind(this)))
        .then((promises) => Promise.all(promises))
        .then(() => {
          resolve()
        })
        .catch((err) => reject(err))
    }.bind(this))
  }

  _updateSignupCountOnSeason(signup) {
    return new Promise(function (resolve, reject) {

      const seasons_path = 'courses/' + signup.courseId + '/seasons'

      this.firestore.collection(seasons_path).doc(signup.seasonId).get()
        .then((data) => {
          const season = this.__doc_to_json(data)
          var new_signups_count = (season.signups - 1)
          var updates = {
            signups: new_signups_count >= 0 ? new_signups_count : 0,
            full: (new_signups_count >= season.max_signups)
          }
          return this.firestore.collection(seasons_path).doc(signup.seasonId).update(updates)
        })
        .then(() => {
          resolve()
        })
        .catch(err => reject(err))
    }.bind(this))
  }

  _setDeletedPropertyOnSignup(signup) {
    return new Promise(function (resolve, reject) {
      this.firestore.collection('signups').doc(signup._id).delete()
        .then(() => resolve())
        .catch(err => reject(err))
    }.bind(this))
  }

  getSignUp(signupId) {
    return new Promise((resolve, reject) => {
      this.firestore.collection('signups')
        .doc(signupId)
        .get()
        .then((doc) => resolve(this.__doc_to_json(doc)))
        .catch((error) => reject(error))
    })
  }

  _validateUser(object) {
    return (
      object.hasOwnProperty('firstname') &&
      object.hasOwnProperty('lastname') &&
      object.hasOwnProperty('email') &&
      object.hasOwnProperty('street') &&
      object.hasOwnProperty('zipcode') &&
      object.hasOwnProperty('town')
    )
  }

  _validateSignUp(object) {
    return (
      object.hasOwnProperty('courseId') &&
      object.hasOwnProperty('seasonId') &&
      object.hasOwnProperty('missoutdate') &&
      object.hasOwnProperty('missoutdateId') &&
      object.hasOwnProperty('language') &&
      object.hasOwnProperty('type') &&
      object.hasOwnProperty('timestamp')
    )
  }

  _updateCourseBoerseAvailableDates(type) {
    try {
      let available_dates = this.state.static_data.home.boerse.available_dates

      switch (type) {
        case 'add':
          available_dates = available_dates + 1
          break;
        case 'remove':
          if (available_dates - 1 >= 0) {
            available_dates = available_dates - 1
          }
          break;
      }
      this.state.static_data.home.boerse.available_dates = available_dates

    } catch (e) {
      console.error(e)
    }
  }

  uploadFile = (file, filename) => {
    return new Promise((resolve, reject) => {
      var storageRef = this.storage.ref();
      var mountainsRef = storageRef.child(filename);

      mountainsRef.put(file)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((downloadURL) => {
          resolve(downloadURL)
        })
        .catch(e => reject(e))
    })
  }

}

export default Firebase




/*sendEmail = (to, subject, text) => this.firestore.collection("mail").doc().set({
    to, message: { subject, text }
})*/

/*getAppData = () => this.firestore.collection("app").doc("img").get().then(function(doc) {
    if (doc.exists) {
        console.log("Document data:", doc.data());
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}).catch(function(error) {
    console.log("Error getting document:", error);
})*/

/*getData = ({collectionId, documentId}) => {
   return this.firestore.collection(collectionId).doc(documentId).get().then((snapshot) => {
       this.setState({
         appdata: snapshot,
         loading: false,
       });
       console.log(snapshot)
     }).catch(function(error) {
       console.log("Error getting document:", error)
     });
}*/

